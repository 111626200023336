/*------------------------------------------------------------------
FDS Theme Overriding Bootstrap v4

1. Variables

-------------------------------------------------------------------*/
/*=================================================
  HELPERS
===================================================
  A. List Helpers
=================================================== */
/* list item spacing helpers */
.list-spacing-3 li + li {
  padding-top: 3px; }

.list-spacing-5 li + li {
  padding-top: 5px; }

.list-spacing-8 li + li {
  padding-top: 8px; }

.list-spacing-10 li + li {
  padding-top: 10px; }

.list-spacing-15 li + li {
  padding-top: 15px; }

/*divide li items with border and extra spacing*/
.list-divide-items {
  margin: 0;
  padding-left: 10px;
  list-style: none; }

.list-divide-items li + li {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid #EEE; }

/*===================================================
  B. Height Helpers
===================================================== */
/* Height helpers, primarly used with panel widgets */
.fixed-height .panel-body {
  overflow: auto; }

.hover-height {
  overflow: hidden;
  transition: all 0.2s ease; }

.mh-20 {
  max-height: 20px !important; }

.mh-25 {
  max-height: 25px !important; }

.mh-30 {
  max-height: 30px !important; }

.mh-35 {
  max-height: 35px !important; }

.mh-40 {
  max-height: 40px !important; }

.mh-50 {
  max-height: 50px !important; }

.mh-100 {
  max-height: 100px !important; }

.mh-200 {
  max-height: 200px !important; }

.mh-300 {
  max-height: 300px !important; }

.mh-400 {
  max-height: 400px !important; }

.mh-500 {
  max-height: 500px !important; }

.mh-600 {
  max-height: 600px !important; }

.mh-700 {
  max-height: 700px !important; }

.mh-800 {
  max-height: 800px !important; }

.mh-900 {
  max-height: 900px !important; }

.mh-1000 {
  max-height: 1000px !important; }

.h-20 {
  height: 20px !important; }

.h-25 {
  height: 25px !important; }

.h-30 {
  height: 30px !important; }

.h-35 {
  height: 35px !important; }

.h-40 {
  height: 40px !important; }

.h-50 {
  height: 50px !important; }

.h-80 {
  height: 80px !important; }

.h-100 {
  height: 100px !important; }

.h-120 {
  height: 120px !important; }

.h-150 {
  height: 150px !important; }

.h-170 {
  height: 170px !important; }

.h-200 {
  height: 200px !important; }

.h-300 {
  height: 300px !important; }

.h-400 {
  height: 400px !important; }

.h-500 {
  height: 500px !important; }

.h-600 {
  height: 600px !important; }

.h-700 {
  height: 700px !important; }

.h-800 {
  height: 800px !important; }

.h-900 {
  height: 900px !important; }

.h-1000 {
  height: 1000px !important; }

/*===================================================
  A. General/Misc Helpers
===================================================== */
/* Transform Center Helper(experimental) */
.t-center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

/* BG Helpers */
.bg-none {
  background-color: transparent !important; }

/* box-shadow Helpers */
.no-boxshadow {
  box-shadow: none !important; }

/* height helpers */
.h-a {
  height: auto !important; }

/* overflow Helpers */
.of-a {
  overflow: auto !important; }

.of-h {
  overflow: hidden !important; }

.of-v {
  overflow: visible !important; }

.of-x-a {
  overflow-x: auto !important; }

.of-x-h {
  overflow-x: hidden !important; }

.of-x-v {
  overflow-x: visible !important; }

.of-y-a {
  overflow-y: auto !important; }

.of-y-h {
  overflow-y: hidden !important; }

.of-y-v {
  overflow-y: visible !important; }

/* Center Grid Column Helper */
.center-column {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important; }

/* Vertical Align Helpers */
.va-t {
  vertical-align: top !important; }

.va-m {
  vertical-align: middle !important; }

.va-b {
  vertical-align: bottom !important; }

.va-s {
  vertical-align: super !important; }

/* Relative Position Helper */
.posr {
  position: relative !important; }

/* Inline Block Helper */
.ib,
.inline-object {
  display: inline-block !important; }

/* pointer cursor */
.cursor {
  cursor: pointer !important; }

/* Useful for emphasizing a disabled input */
.option-disabled {
  opacity: 0.6 !important; }

/* unstyled input */
.input-unstyled,
.input-unstyled:hover,
.input-unstyled:focus {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  outline: none !important; }

/*===================================================
  B. Table Layout Helpers - specifically for widgets
===================================================== */
/* table-layout for connecting panels */
.panel-group.table-layout > .panel + .panel {
  border-left: 0; }

.panel-group.table-layout + .panel-group.table-layout > .panel {
  border-top: 0; }

/* table-layout when attached to row */
.row.table-layout {
  margin-left: 0;
  margin-right: 0; }

.table-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0; }

/* table-layout helper content */
.table-layout > div,
.table-layout > aside,
.table-layout > section {
  vertical-align: middle;
  display: table-cell;
  float: none; }

@media (max-width: 1300px) {
  .table-layout.table-clear-md,
  .table-layout.table-clear-md > div,
  .table-layout.table-clear-md > aside,
  .table-layout.table-clear-md > section {
    display: block;
    float: none; } }

@media (max-width: 992px) {
  .table-layout.table-clear-sm,
  .table-layout.table-clear-sm > div,
  .table-layout.table-clear-sm > aside,
  .table-layout.table-clear-sm > section {
    display: block;
    float: none; } }

@media (max-width: 767px) {
  .table-layout.table-clear-xs,
  .table-layout.table-clear-xs > div,
  .table-layout.table-clear-xs > aside,
  .table-layout.table-clear-xs > section {
    display: block;
    float: none; } }

/*===============================================
  C. Text Helpers
================================================= */
.text-uppercase {
  text-transform: uppercase !important; }

.fw200 {
  font-weight: 200 !important; }

.fw300 {
  font-weight: 300 !important; }

.fw400 {
  font-weight: 400 !important; }

.fw600 {
  font-weight: 600 !important; }

.fs3 {
  font-size: 4px !important; }

.fs4 {
  font-size: 4px !important; }

.fs5 {
  font-size: 5px !important; }

.fs6 {
  font-size: 6px !important; }

.fs7 {
  font-size: 7px !important; }

.fs8 {
  font-size: 8px !important; }

.fs9 {
  font-size: 9px !important; }

.fs10 {
  font-size: 10px !important; }

.fs11 {
  font-size: 11px !important; }

.fs12 {
  font-size: 12px !important; }

.fs13 {
  font-size: 13px !important; }

.fs14 {
  font-size: 14px !important; }

.fs15 {
  font-size: 15px !important; }

.fs16 {
  font-size: 16px !important; }

.fs17 {
  font-size: 17px !important; }

.fs18 {
  font-size: 18px !important; }

.fs20 {
  font-size: 20px !important; }

.fs22 {
  font-size: 22px !important; }

.fs24 {
  font-size: 24px !important; }

.fs26 {
  font-size: 26px !important; }

.fs28 {
  font-size: 28px !important; }

.fs30 {
  font-size: 30px !important; }

.fs35 {
  font-size: 35px !important; }

.fs40 {
  font-size: 40px !important; }

.fs45 {
  font-size: 45px !important; }

.fs50 {
  font-size: 50px !important; }

.fs60 {
  font-size: 60px !important; }

.fs70 {
  font-size: 70px !important; }

.fs80 {
  font-size: 80px !important; }

.fs90 {
  font-size: 90px !important; }

.fs100 {
  font-size: 100px !important; }

/*===============================================
  D. Padding Helpers
================================================= */
.pn {
  padding: 0 !important; }

.p1 {
  padding: 1px !important; }

.p2 {
  padding: 2px !important; }

.p3 {
  padding: 3px !important; }

.p4 {
  padding: 4px !important; }

.p5 {
  padding: 5px !important; }

.p6 {
  padding: 6px !important; }

.p7 {
  padding: 7px !important; }

.p8 {
  padding: 8px !important; }

.p10 {
  padding: 10px !important; }

.p12 {
  padding: 12px !important; }

.p15 {
  padding: 15px !important; }

.p20 {
  padding: 20px !important; }

.p25 {
  padding: 25px !important; }

.p30 {
  padding: 30px !important; }

.p35 {
  padding: 35px !important; }

.p40 {
  padding: 40px !important; }

.p50 {
  padding: 50px !important; }

.ptn {
  padding-top: 0 !important; }

.pt5 {
  padding-top: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt25 {
  padding-top: 25px !important; }

.pt30 {
  padding-top: 30px !important; }

.pt35 {
  padding-top: 35px !important; }

.pt40 {
  padding-top: 40px !important; }

.pt50 {
  padding-top: 50px !important; }

.prn {
  padding-right: 0 !important; }

.pr5 {
  padding-right: 5px !important; }

.pr10 {
  padding-right: 10px !important; }

.pr15 {
  padding-right: 15px !important; }

.pr20 {
  padding-right: 20px !important; }

.pr25 {
  padding-right: 25px !important; }

.pr30 {
  padding-right: 30px !important; }

.pr35 {
  padding-right: 35px !important; }

.pr40 {
  padding-right: 40px !important; }

.pr50 {
  padding-right: 50px !important; }

.pbn {
  padding-bottom: 0 !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pln {
  padding-left: 0 !important; }

.pl5 {
  padding-left: 5px !important; }

.pl10 {
  padding-left: 10px !important; }

.pl15 {
  padding-left: 15px !important; }

.pl20 {
  padding-left: 20px !important; }

.pl25 {
  padding-left: 25px !important; }

.pl30 {
  padding-left: 30px !important; }

.pl35 {
  padding-left: 35px !important; }

.pl40 {
  padding-left: 40px !important; }

.pl50 {
  padding-left: 50px !important; }

/* Axis Padding (both top/bottom or left/right) */
.pv5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pv8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.pv15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pv20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.pv25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }

.pv30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pv40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.pv50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.ph5 {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.ph8 {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.ph10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.ph15 {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.ph20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.ph25 {
  padding-left: 25px !important;
  padding-right: 25px !important; }

.ph30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.ph40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.ph50 {
  padding-left: 50px !important;
  padding-right: 50px !important; }

/* responsive padding helpers */
@media (min-width: 1140px) {
  .ptn-lg {
    padding-top: 0 !important; }
  .prn-lg {
    padding-right: 0 !important; }
  .pbn-lg {
    padding-bottom: 0 !important; }
  .pln-lg {
    padding-left: 0 !important; }
  .pt5-lg {
    padding-top: 5px !important; }
  .pr5-lg {
    padding-right: 5px !important; }
  .pb5-lg {
    padding-bottom: 5px !important; }
  .pl5-lg {
    padding-left: 5px !important; } }

@media (min-width: 992px) {
  .ptn-md {
    padding-top: 0 !important; }
  .prn-md {
    padding-right: 0 !important; }
  .pbn-md {
    padding-bottom: 0 !important; }
  .pln-md {
    padding-left: 0 !important; }
  .pt5-md {
    padding-top: 5px !important; }
  .pr5-md {
    padding-right: 5px !important; }
  .pb5-md {
    padding-bottom: 5px !important; }
  .pl5-md {
    padding-left: 5px !important; } }

/*===============================================
  E. Margin Helpers
================================================= */
/* margin center helper */
.mauto {
  margin-left: auto;
  margin-right: auto; }

.mn {
  margin: 0 !important; }

.m1 {
  margin: 1px !important; }

.m2 {
  margin: 2px !important; }

.m3 {
  margin: 3px !important; }

.m4 {
  margin: 4px !important; }

.m5 {
  margin: 5px !important; }

.m8 {
  margin: 8px !important; }

.m10 {
  margin: 10px !important; }

.m15 {
  margin: 15px !important; }

.m20 {
  margin: 20px !important; }

.m25 {
  margin: 25px !important; }

.m30 {
  margin: 30px !important; }

.m35 {
  margin: 35px !important; }

.m40 {
  margin: 40px !important; }

.m50 {
  margin: 50px !important; }

.mtn {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.mt50 {
  margin-top: 50px !important; }

.mt70 {
  margin-top: 70px !important; }

.mrn {
  margin-right: 0 !important; }

.mr5 {
  margin-right: 5px !important; }

.mr10 {
  margin-right: 10px !important; }

.mr15 {
  margin-right: 15px !important; }

.mr20 {
  margin-right: 20px !important; }

.mr25 {
  margin-right: 25px !important; }

.mr30 {
  margin-right: 30px !important; }

.mr35 {
  margin-right: 35px !important; }

.mr40 {
  margin-right: 40px !important; }

.mr50 {
  margin-right: 50px !important; }

.mbn {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.mln {
  margin-left: 0 !important; }

.ml5 {
  margin-left: 5px !important; }

.ml10 {
  margin-left: 10px !important; }

.ml15 {
  margin-left: 15px !important; }

.ml20 {
  margin-left: 20px !important; }

.ml25 {
  margin-left: 25px !important; }

.ml30 {
  margin-left: 30px !important; }

.ml35 {
  margin-left: 35px !important; }

.ml40 {
  margin-left: 40px !important; }

.ml50 {
  margin-left: 50px !important; }

/* Axis Margins (both top/bottom or left/right) */
.mv5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.mv10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.mv15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.mv20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.mv25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.mv30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.mv40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.mv50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.mv70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.mh5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mh10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.mh15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mh20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mh25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.mh30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mh40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.mh50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mh70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

/* Negative Margin Helpers */
.mtn5 {
  margin-top: -5px !important; }

.mtn10 {
  margin-top: -10px !important; }

.mtn15 {
  margin-top: -15px !important; }

.mtn20 {
  margin-top: -20px !important; }

.mtn30 {
  margin-top: -30px !important; }

.mrn5 {
  margin-right: -5px !important; }

.mrn10 {
  margin-right: -10px !important; }

.mrn15 {
  margin-right: -15px !important; }

.mrn20 {
  margin-right: -20px !important; }

.mrn30 {
  margin-right: -30px !important; }

.mbn5 {
  margin-bottom: -5px !important; }

.mbn10 {
  margin-bottom: -10px !important; }

.mbn15 {
  margin-bottom: -15px !important; }

.mbn20 {
  margin-bottom: -20px !important; }

.mbn30 {
  margin-bottom: -30px !important; }

.mln5 {
  margin-left: -5px !important; }

.mln10 {
  margin-left: -10px !important; }

.mln15 {
  margin-left: -15px !important; }

.mln20 {
  margin-left: -20px !important; }

.mln30 {
  margin-left: -30px !important; }

/* Vertical Negative Margin "mv" + "n" + "x" */
.mvn5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important; }

.mvn10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important; }

.mvn15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important; }

.mvn20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important; }

.mvn30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important; }

/* Horizontal Negative Margin "mh" + "n" + "x" */
.mhn5 {
  margin-left: -5px !important;
  margin-right: -5px !important; }

.mhn10 {
  margin-left: -10px !important;
  margin-right: -10px !important; }

.mhn15 {
  margin-left: -15px !important;
  margin-right: -15px !important; }

.mhn20 {
  margin-left: -20px !important;
  margin-right: -20px !important; }

.mhn30 {
  margin-left: -30px !important;
  margin-right: -30px !important; }

/*===============================================
  F. LineHeight Helpers
================================================= */
.lh0 {
  line-height: 0 !important; }

.lh5 {
  line-height: 5px !important; }

.lh10 {
  line-height: 10px !important; }

.lh15 {
  line-height: 15px !important; }

.lh20 {
  line-height: 20px !important; }

.lh25 {
  line-height: 25px !important; }

.lh30 {
  line-height: 30px !important; }

.lh35 {
  line-height: 35px !important; }

.lh40 {
  line-height: 40px !important; }

/*===============================================
  G. Border Helpers
================================================= */
/* Border Disables */
.br-l-n {
  border-left: none !important; }

.br-r-n {
  border-right: none !important; }

.br-t-n {
  border-top: none !important; }

.br-b-n {
  border-bottom: none !important; }

.br-n {
  border: none !important; }

.br-v-n {
  border-top: none !important;
  border-bottom: none !important; }

.br-h-n {
  border-left: none !important;
  border-right: none !important; }

/* Default Borders */
.br-a {
  border: 1px solid #EEE !important; }

.br-l {
  border-left: 1px solid #EEE !important; }

.br-r {
  border-right: 1px solid #EEE !important; }

.br-t {
  border-top: 1px solid #EEE !important; }

.br-b {
  border-bottom: 1px solid #EEE !important; }

/* Border Style */
.br-dashed {
  border-style: dotted !important; }

/* Border Radius */
.br0 {
  border-radius: 0px !important; }

.br1 {
  border-radius: 1px !important; }

.br2 {
  border-radius: 2px !important; }

.br3 {
  border-radius: 3px !important; }

.br4 {
  border-radius: 4px !important; }

.br6 {
  border-radius: 6px !important; }

.br8 {
  border-radius: 8px !important; }

.br12 {
  border-radius: 12px !important; }

.br24 {
  border-radius: 24px !important; }

.br64 {
  border-radius: 64px !important; }

/* Border Width */
.bw1 {
  border-width: 1px !important; }

.bw2 {
  border-width: 2px !important; }

.bw3 {
  border-width: 3px !important; }

.bw4 {
  border-width: 4px !important; }

.bw5 {
  border-width: 5px !important; }

.bw8 {
  border-width: 8px !important; }

.bw10 {
  border-width: 10px !important; }

.bw15 {
  border-width: 15px !important; }

/*Border Colors Generate contextual modifier classes for colorizing the alert.
*/
.br-white {
  border-color: #FFF !important; }

.br-lighter {
  border-color: #EEE !important; }

.br-light {
  border-color: #E7E7E7 !important; }

.br-grey {
  border-color: #d9d9d9 !important; }

.br-greyer {
  border-color: #CCC !important; }

.br-tp-top {
  border-top-color: rgba(0, 0, 0, 0.1); }

.br-tp-right {
  border-right-color: rgba(0, 0, 0, 0.1); }

.br-tp-left {
  border-left-color: rgba(0, 0, 0, 0.1); }

/*===============================================
  H. Max Width Helpers
================================================= */
.fluid-width {
  width: 100% !important; }

.mw10 {
  max-width: 10px !important; }

.mw20 {
  max-width: 20px !important; }

.mw30 {
  max-width: 30px !important; }

.mw35 {
  max-width: 35px !important; }

.mw40 {
  max-width: 40px !important; }

.mw45 {
  max-width: 45px !important; }

.mw50 {
  max-width: 50px !important; }

.mw60 {
  max-width: 60px !important; }

.mw80 {
  max-width: 80px !important; }

.mw100 {
  max-width: 100px !important; }

.mw140 {
  max-width: 140px !important; }

.mw160 {
  max-width: 160px !important; }

.mw180 {
  max-width: 180px !important; }

.mw200 {
  max-width: 200px !important; }

.mw240 {
  max-width: 240px !important; }

.mw280 {
  max-width: 280px !important; }

.mw320 {
  max-width: 320px !important; }

.mw400 {
  max-width: 400px !important; }

.mw450 {
  max-width: 450px !important; }

.mw500 {
  max-width: 500px !important; }

.mw600 {
  max-width: 600px !important; }

.mw700 {
  max-width: 700px !important; }

.mw800 {
  max-width: 800px !important; }

.mw900 {
  max-width: 900px !important; }

.mw1000 {
  max-width: 1000px !important; }

.mw1100 {
  max-width: 1100px !important; }

.mw1200 {
  max-width: 1200px !important; }

/*Regular Width Helpers. Primarily used
on tables, feel free to add more*/
.w20 {
  width: 20px !important; }

.w30 {
  width: 30px !important; }

.w40 {
  width: 40px !important; }

.w50 {
  width: 50px !important; }

.w75 {
  width: 75px !important; }

.w100 {
  width: 100px !important; }

.w125 {
  width: 125px !important; }

.w150 {
  width: 150px !important; }

.w175 {
  width: 175px !important; }

.w200 {
  width: 200px !important; }

.w225 {
  width: 225px !important; }

.w250 {
  width: 250px !important; }

.w275 {
  width: 275px !important; }

.w300 {
  width: 300px !important; }

.w320 {
  width: 320px !important; }

.w350 {
  width: 350px !important; }

.w375 {
  width: 375px !important; }

.w400 {
  width: 400px !important; }

.w450 {
  width: 450px !important; }

.mnw20 {
  min-width: 20px !important; }

.mnw30 {
  min-width: 30px !important; }

.mnw40 {
  min-width: 40px !important; }

.mnw50 {
  min-width: 50px !important; }

.mnw75 {
  min-width: 75px !important; }

.mnw100 {
  min-width: 100px !important; }

.mnw125 {
  min-width: 125px !important; }

.mnw150 {
  min-width: 150px !important; }

.mnw175 {
  min-width: 175px !important; }

.mnw200 {
  min-width: 200px !important; }

.mnw250 {
  min-width: 250px !important; }

.mnw300 {
  min-width: 300px !important; }

.mnw500 {
  min-width: 500px !important; }

.mnw700 {
  min-width: 700px !important; }

.mnw1000 {
  min-width: 1000px !important; }

/* Collection default theme */
/* Grid default theme */
.ReactVirtualized__Grid {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  outline: none; }

.ReactVirtualized__Grid__innerScrollContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-width: none !important; }

/* Table default theme */
.ReactVirtualized__Table {
  table-layout: fixed;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap; }

.ReactVirtualized__Table__Grid {
  outline: none; }

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-direction: row; }

.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  flex-grow: 1; }

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  padding: 10px;
  margin-left: 0 !important;
  margin_right: 0 !important;
  min-width: 0px; }

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Enable when getRowHeight works properly white-space: pre-line;*/ }

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0 !important;
  margin_right: 0 !important; }

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer; }

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex; }

.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor; }

/* List default theme */
.Table {
  width: 100%;
  margin-top: 15px; }

.headerRow,
.editingRow,
.evenRow,
.oddRow {
  /*border-bottom: 1px solid #e0e0e0;*/
  margin: 0; }

.editingRow {
  background-color: #F5F5F5; }

.oddRow {
  background-color: #FAFAFA; }

.stripedRow {
  background-color: #F5F5F5; }

.firstRow {
  overflow-x: auto !important; }

.headerColumn {
  text-transform: none; }

.exampleColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.checkboxLabel {
  margin-left: .5rem; }

.checkboxLabel:first-of-type {
  margin-left: 0; }

.noRows {
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  font-size: 1em;
  color: #666666; }

.HeaderGrid {
  display: flex;
  flex-direction: row;
  outline: none; }

.pt-control.pt-checkbox span.pt-control-indicator {
  outline: none; }

/*Layout and common styles
------------------------------------
*/
html, body, #root {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background-color: #eee; }

#content {
  padding-top: 10px; }

a:not([href]):not([tabindex]) {
  cursor: pointer; }

header .nav-item {
  width: 200px; }
  header .nav-item a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-bottom: 5px;
    text-decoration: none; }
  header .nav-item a:hover, header .nav-item a.active {
    padding-bottom: 0;
    background-color: #2F3862;
    border-bottom: #4256AD 5px solid;
    color: white; }

.nav-tabs > li > a.active::after {
  background-color: #4256AD;
  color: #61542F; }

.badge-primary {
  background-color: #2F3862; }

.btn-custom {
  color: #fff !important;
  background-color: #2F3862;
  border-color: #2F3862; }

.btn-custom:hover {
  color: #fff;
  background-color: #2F3862;
  border-color: #2F3862; }

.icon-custom {
  cursor: pointer;
  transition: color 0.5s ease-in-out; }

.icon-custom:hover {
  color: #2F3862; }

.pt-control input:checked ~ .pt-control-indicator, .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator {
  background-color: black; }

.login-wrapper {
  height: 100%;
  background-color: #fff; }
  .login-wrapper > * {
    height: 100%; }
  .login-wrapper .bg-pic {
    position: absolute;
    width: 100%;
    overflow: hidden; }
    .login-wrapper .bg-pic > img {
      opacity: 1; }
  .login-wrapper .login-container {
    width: 496px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    float: right; }
  .login-wrapper .bg-caption {
    width: 500px; }

.register-container {
  width: 550px;
  margin: auto;
  height: 100%; }

/* Tabs
------------------------------------
*/
.nav-tabs {
  position: relative; }
  .nav-tabs > li {
    padding-left: 0;
    padding-right: 0; }
    .nav-tabs > li > a.active {
      font-weight: bold; }
      .nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
        border-color: #e6e6e6;
        color: #626262;
        background-color: #e6e6e6; }
    .nav-tabs > li > a {
      display: block;
      border-radius: 0;
      padding: 13px 20px;
      margin-right: 0;
      font-weight: 500;
      letter-spacing: 0.06em;
      color: fade(#626262, 70%);
      font-size: 13px;
      min-width: 70px;
      border-color: transparent;
      position: relative;
      line-height: 1.7em; }
      .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
        background: transparent;
        border-color: transparent;
        color: #626262; }
      .nav-tabs > li > a .tab-icon {
        margin-right: 6px; }
  .nav-tabs ~ .tab-content {
    overflow: hidden;
    padding: 15px; }
    .nav-tabs ~ .tab-content > .tab-pane.slide-left, .nav-tabs ~ .tab-content > .tab-pane.slide-right {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .nav-tabs ~ .tab-content > .tab-pane.slide-left.sliding, .nav-tabs ~ .tab-content > .tab-pane.slide-right.sliding {
        opacity: 0 !important; }
      .nav-tabs ~ .tab-content > .tab-pane.slide-left.active, .nav-tabs ~ .tab-content > .tab-pane.slide-right.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -ms-transform: translate(0, 0);
        opacity: 1; }
    .nav-tabs ~ .tab-content > .tab-pane.slide-left.sliding {
      -webkit-transform: translate3d(10%, 0, 0);
      transform: translate3d(10%, 0, 0);
      -ms-transform: translate(10%, 0); }
    .nav-tabs ~ .tab-content > .tab-pane.slide-right.sliding {
      -webkit-transform: translate3d(-10%, 0, 0);
      transform: translate3d(-10%, 0, 0);
      -ms-transform: translate(-10%, 0); }
  .nav-tabs.nav-tabs-left, .nav-tabs.nav-tabs-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    .nav-tabs.nav-tabs-left:after, .nav-tabs.nav-tabs-right:after {
      border-bottom: 0px; }
    .nav-tabs.nav-tabs-left > li, .nav-tabs.nav-tabs-right > li {
      float: none; }
  .nav-tabs.nav-tabs-left {
    float: left;
    padding-right: 0; }
    .nav-tabs.nav-tabs-left ~ .tab-content {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      -ms-flex: 1;
      -ms-flex-negative: 0; }
  .nav-tabs.nav-tabs-right {
    float: right;
    padding-right: 0; }
    .nav-tabs.nav-tabs-right ~ .tab-content {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      -ms-flex: 1;
      -ms-flex-negative: 0; }

/* Tabs : Simple
------------------------------------
*/
.nav-tabs-simple {
  border-bottom: 0px; }
  .nav-tabs-simple:after {
    content: '';
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    position: absolute;
    bottom: -2px;
    z-index: 120;
    left: 0; }
  .nav-tabs-simple.inside-page-header:after {
    content: none; }
  .nav-tabs-simple > li {
    margin-bottom: 0; }
    .nav-tabs-simple > li > a:after {
      position: absolute;
      -webkit-transition: all 0.1s linear 0s;
      transition: all 0.1s linear 0s;
      -webkit-backface-visibility: hidden;
      width: 100%;
      display: block;
      background-color: #076BAD;
      height: 0px;
      content: '';
      left: 0;
      z-index: 125;
      top: 100%; }
      .nav-tabs-simple > li > a:after.nav-tabs-primary {
        background-color: #076BAD; }
      .nav-tabs-simple > li > a:after.nav-tabs-success {
        background-color: #66BB6A; }
      .nav-tabs-simple > li > a:after.nav-tabs-complete {
        background-color: #48b0f7; }
      .nav-tabs-simple > li > a:after.nav-tabs-danger {
        background-color: #f55753; }
      .nav-tabs-simple > li > a:after.nav-tabs-warning {
        background-color: #f8d053; }
      .nav-tabs-simple > li > a:after.nav-tabs-info {
        background-color: #3b4752; }
    .nav-tabs-simple > li > a.active:hover, .nav-tabs-simple > li > a.active:focus {
      background-color: transparent;
      border-color: transparent; }
    .nav-tabs-simple > li > a.active:after, .nav-tabs-simple > li > a:hover:after {
      height: 3px; }
  .nav-tabs-simple.nav-tabs-left:after, .nav-tabs-simple.nav-tabs-right:after {
    border-bottom: 0px; }
  .nav-tabs-simple.nav-tabs-left > li > a:after, .nav-tabs-simple.nav-tabs-right > li > a:after {
    width: 0px;
    height: 100%;
    top: 0;
    bottom: 0;
    position: absolute; }
  .nav-tabs-simple.nav-tabs-left > li > a.active:after, .nav-tabs-simple.nav-tabs-left > li > a:hover:after, .nav-tabs-simple.nav-tabs-right > li > a.active:after, .nav-tabs-simple.nav-tabs-right > li > a:hover:after {
    width: 3px; }
  .nav-tabs-simple.nav-tabs-left > li > a:after {
    right: 0;
    left: auto; }
  .nav-tabs-simple.nav-tabs-right > li > a:after {
    left: 0; }

input.filter-text-input {
  height: 34px;
  line-height: 34px;
  padding: 0 10px;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border: 1px solid #ccc;
  color: #333; }

.container-fluid.ficha {
  padding: 0 20px; }

.container-fluid.ficha b {
  margin-right: 5px; }

.sign-header {
  display: flex;
  height: 60px; }

@media (max-width: 1200px) {
  .sc-ifAKCX.kUScrQ {
    top: 20px !important;
    height: calc(100vh - 100px); } }
